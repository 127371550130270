<template>
  <div>
    <!-- <div v-else> -->
    <b-overlay
      :show="show"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <b-row>
        <!-- หน้าซื้อ -->
        <b-col
          v-if="Title.LottoHead && statusCheck === true"
          lg="7"
          md="12"
        >
          <!-- <b-col> -->
          <b-card>
            <p
              class="font-weight-bolder"
              style="font-size: 20px; text-align: center;"
            >
              <span class="text-danger font-weight-bolder">{{ NameShow }} </span>เหลือเวลาอีก <span class="text-danger">{{ CloseTimeShow ? CloseTimeShow : '-' }} ชม.</span>
            </p>
            <b-row class="header-bg-content2">
              <b-col
                lg="4"
                md="4"
                cols="4"
                class="p-0"
              >
                <div>
                  <button
                    :class="`btn-type3 btnp ${ShowDigit === 3 ? 'active' : ''}`"
                    @click="SelectDigit(3) , NumArrCartInputStep1Show = [] , AmountBet = null , AmountBet2 = null , NumArrCartInputStep1 = null"
                  >
                    หวย 3 ตัว
                  </button>
                </div>
              </b-col>
              <b-col
                lg="4"
                md="4"
                cols="4"
                class="p-0"
              >
                <div>
                  <button
                    :class="`btn-type2 btnp ${ShowDigit === 2 ? 'active' : ''}`"
                    @click="SelectDigit(2) , NumArrCartInputStep1Show = [] , AmountBet = null , AmountBet2 = null , NumArrCartInputStep1 = null"
                  >
                    หวย 2 ตัว
                  </button>
                </div>
              </b-col>
              <b-col
                lg="4"
                md="4"
                cols="4"
                class="p-0"
              >
                <div>
                  <button
                    :class="`btn-type1 btnp ${ShowDigit === 1 ? 'active' : ''}`"
                    @click="SelectDigit(1) , NumArrCartInputStep1Show = [] , AmountBet = null , AmountBet2 = null , NumArrCartInputStep1 = null"
                  >
                    เลขวิ่ง
                  </button>
                </div>
              </b-col>
            </b-row>
            <div>
              <div class="mt-1 text-center">
                <strong>เลือกประเภทการซื้อ</strong><br>
                <!-- 3 ตัว -->
                <!-- <div
                v-if="ShowDigit === 3"
                class="row"
              > -->
                <!-- <div class="col-4">
                  <button
                    class="btn btn-outline-success btn-block"
                    :class="[isActive === true ? 'active' : '']"
                    @click="SelectBetType3('3ตัวบน')"
                  ><i class="fas fa-plus-circle" />
                    3 ตัวบน
                  </button>
                </div>

                <div class="col-4">
                  <button
                    class="btn btn-outline-success btn-block"
                    @click="SelectBetType3('3ตัวโต๊ด')"
                  ><i
                    class="fas fa-plus-circle"
                  />
                    3 ตัวโต๊ด
                  </button>
                </div> -->

                <!-- <div class="col-4">
                  <button
                    class="btn btn-outline-success btn-block"
                    @click="SelectBetType3('3ตัวหน้า')"
                  ><i
                    class="fas fa-plus-circle"
                  />
                    3 ตัวหน้า
                  </button>
                </div>

                <div class="col-4">
                  <button
                    class="btn btn-outline-success btn-block"
                    @click="SelectBetType3('3ตัวท้าย')"
                  ><i
                    class="fas fa-plus-circle"
                  />
                    3 ตัวท้าย
                  </button>
                </div> -->

                <!-- <div class="col-4"> -->
                <button
                  v-if="ShowDigit === 3"
                  class="btn btn-outline-success mb-1"
                  @click="SelectBetType3('กลับตัวเลข'), NumArrCartInputStep1Show = [] , AmountBet = null , AmountBet2 = null , NumArrCartInputStep1 = null"
                ><i
                  class="fas fa-plus-circle"
                />
                  6 ตัวกลับ
                </button>
                <!-- </div>
              </div> -->

                <!-- 2 ตัว -->
                <!-- <div
                v-if="ShowDigit === 2"
                class="row row-btn-tanghuay setting__number"
              > -->
                <!-- <div class="col-4 col-sm-4 col-md-4 col-lg-4">
                  <button
                    class="btn btn-outline-warning btn-block"
                    @click="SelectBetType2('2ตัวบน')"
                  ><i
                    class="fas fa-plus-circle"
                  />
                    2 ตัวบน
                  </button>
                </div>

                <div class="col-4 col-sm-4 col-md-4 col-lg-4">
                  <button
                    class="btn btn-outline-warning btn-block"
                    @click="SelectBetType2('2ตัวล่าง')"
                  ><i
                    class="fas fa-plus-circle"
                  />
                    2 ตัวล่าง
                  </button>

                </div> -->
                <!-- <div class="col-4 col-sm-4 col-md-4 col-lg-4"> -->
                <button
                  v-if="ShowDigit === 2"
                  class="btn btn-outline-warning mb-1"
                  @click="SelectBetType2('19ประตู'), NumArrCartInputStep1Show = [] , AmountBet = null , AmountBet2 = null , NumArrCartInputStep1 = null"
                ><i
                  class="fas fa-plus-circle"
                />
                  19 ประตู
                </button>

                <!-- </div> -->
                <!-- </div> -->

                <!-- 1 ตัว -->
                <div
                  v-if="ShowDigit === 1"
                  class="d-flex justify-content-center mb-1"
                >
                  <!-- <div class="col-6 col-sm-6 col-md-4 col-lg-3"> -->
                  <button
                    class="btn btn-outline-info mr-1"
                    @click="SelectBetType1('วิ่งบน'), NumArrCartInputStep1Show = [] , AmountBet = null , AmountBet2 = null"
                  ><i
                    class="fas fa-plus-circle"
                  />
                    วิ่งบน
                  </button>
                  <!-- </div> -->

                  <!-- <div class="col-6 col-sm-6 col-md-4 col-lg-3"> -->
                  <button
                    class="btn btn-outline-info"
                    @click="SelectBetType1('วิ่งล่าง'), NumArrCartInputStep1Show = [] , AmountBet = null , AmountBet2 = null"
                  ><i
                    class="fas fa-plus-circle"
                  />
                    วิ่งล่าง
                  </button>

                <!-- </div> -->
                </div>

                <!-- แทงตัวเลข -->
                <div>
                  <div class="key-pad box__keyboard">
                    <!-- <div
              v-if="
                BetType.indexOf('3ตัวบน') >= 0 ||
                  BetType.indexOf('3ตัวล่าง') >= 0 ||
                  BetType.indexOf('3ตัวโต๊ด') >= 0 ||
                  BetType.indexOf('2ตัวบน') >= 0 ||
                  BetType.indexOf('2ตัวล่าง') >= 0 ||
                  BetType.indexOf('2ตัวโต๊ด(บน)') >= 0 ||
                  BetType.indexOf('วิ่งบน') >= 0 ||
                  BetType.indexOf('วิ่งล่าง') >= 0
              "
            > -->
                    <div>

                      <div class="key-pad box__keyboard">
                        <h6
                          v-if="!CheckBetType"
                          class="text-center"
                        >
                          ระบุตัวเลข {{ CalMaxLength() }} ตัว
                        </h6>
                        <h6
                          v-else
                          class="text-center"
                        >
                          กรุณาเลือกประเภท
                        </h6>

                        <div
                          v-if="BetType.length > 0"
                          class="text-center"
                        >
                          <b-badge
                            v-for="item in BetType"
                            :key="`BetType${item}`"
                            :variant="
                              item === 'กลับตัวเลข' ||
                                item === 'รูดหน้า' ||
                                item === 'รูดหลัง'
                                ? 'dark'
                                : ShowDigit === 3
                                  ? 'danger'
                                  : ShowDigit === 2
                                    ? 'warning'
                                    : 'info'
                            "
                            style="margin-right: 0.3rem"
                          >
                            {{ item }}
                          </b-badge>
                        </div>
                      </div>
                      <hr>
                      <div
                        class="card-numlot mt-2"
                      >
                        <b-badge
                          v-for="itemss in NumArrCartInputStep1Show"
                          :key="itemss"
                          variant="primary"
                          style="margin: 1px"
                        >
                          {{ itemss }}
                        </b-badge>
                      </div>
                      <div
                        class="row d-flex align-items-center justify-content-center"
                      >
                        <div class="col-3">
                          <div class="d-flex align-items-center">
                            <label class="ip-txt">ใส่เลข</label>
                            <input
                              id="InputNum"
                              v-model="NumArrCartInputStep1"
                              class="text-center input-col"

                              @input="AddCartInputStep1(NumArrCartInputStep1)"
                            >
                          </div>
                        </div>
                        <div
                          v-if="ShowDigit !== 1"
                          class="col-3"
                        >
                          <div class="d-flex align-items-center">
                            <label class="ip-txt">ราคาบน</label>
                            <input
                              v-if="BugOtp === true"
                              v-model="AmountBet"
                              class="text-center input-col"
                              type="number"
                            >
                          </div>
                        </div>
                        <div
                          v-if="ShowDigit === 1"
                          class="col-3"
                        >
                          <div class="d-flex align-items-center">
                            <label class="ip-txt">ราคาวิ่งบน</label>
                            <input
                              v-if="BugOtp === true"
                              v-model="AmountBet"
                              class="text-center input-col"
                              type="number"
                            >
                          </div>
                        </div>
                        <div
                          v-if="ShowDigit === 3"
                          class="col-3"
                        >
                          <div class="d-flex align-items-center">
                            <label class="ip-txt">ราคาโต๊ด</label>
                            <input
                              v-if="BugOtp === true"
                              v-model="AmountBet2"
                              :disabled="BetType.indexOf('3ตัวโต๊ด') <= 0"
                              class="text-center input-col"
                              type="number"
                            >
                          </div>
                        </div>
                        <div
                          v-if="ShowDigit === 2"
                          class="col-3"
                        >
                          <div class="d-flex align-items-center">
                            <label class="ip-txt">ราคาล่าง</label>
                            <input
                              v-if="BugOtp === true"
                              v-model="AmountBet2"
                              class="text-center input-col"
                              type="number"
                            >
                          </div>
                        </div>
                        <div
                          v-if="ShowDigit === 1"
                          class="col-3"
                        >
                          <div class="d-flex align-items-center">
                            <label class="ip-txt">ราคาวิ่งล่าง</label>
                            <input
                              v-if="BugOtp === true"
                              v-model="AmountBet2"
                              class="text-center input-col"
                              type="number"
                            >
                          </div>
                        </div>
                        <!-- <div class="col-3" /> -->
                        <div class="col-3">
                          <button
                            class="btn btn-warning btn-block btn-w"
                            @click="step1 = true , AddCart(NumArrCartInputStep1Show)"
                          >
                            ยืนยัน
                          </button>
                        </div>
                      <!-- <div class="col-3" /> -->
                      </div>

                      <hr
                        class="mt-1"
                        style="border-bottom: 2px solid #c7c7c7bd;"
                      >
                    </div>
                  </div>
                </div>

                <div>
                  <div
                    v-for="(item,index) in NumArrCartInputStep2Show"
                    :key="item"
                  >
                    <!-- <hr> -->
                    <table
                      v-if="step1 === true"
                      width="100%"
                    >
                      <colgroup>
                        <col width="20%">
                        <col width="70%">
                        <col width="10%">
                      </colgroup>
                      <tbody>
                        <tr style="border-bottom: 1px solid #c7c7c76d;">
                          <td
                            colspan="1"
                            class="text-center"
                          >
                            <label
                              v-if="NumArrCartInputStep2Cal[index][0] === 3"
                              style="font-size: 14px"
                            >
                              3 ตัว <br>
                              <span
                                class="text-danger"
                                style="font-size: 13px"
                              >บน x โต๊ด</span><br>
                              {{ NumArrCartInputStep2Cal[index][1] }} x {{ NumArrCartInputStep2Cal[index][2] }}<br>
                            </label>
                            <label
                              v-if="NumArrCartInputStep2Cal[index][0] === 2"
                              style="font-size: 14px"
                            >
                              2 ตัว <br>
                              <span
                                class="text-danger"
                                style="font-size: 13px"
                              >บน x ล่าง</span><br>
                              {{ NumArrCartInputStep2Cal[index][1] }} x {{ NumArrCartInputStep2Cal[index][2] }}<br>
                            </label>
                            <label
                              v-if="NumArrCartInputStep2Cal[index][0] === 1"
                              style="font-size: 14px"
                            >
                              เลขวิ่ง <br>
                              <span
                                class="text-danger"
                                style="font-size: 13px"
                              >บน x ล่าง</span><br>
                              {{ NumArrCartInputStep2Cal[index][1] }} x {{ NumArrCartInputStep2Cal[index][2] }}<br>
                            </label>
                          </td>
                          <td
                            colspan="1"
                            style="padding: 10px 0"
                          >
                            <div
                              v-if="step1 === true && item.length > 0"
                              class="card-sum text-center"
                            >
                              <b-badge
                                v-for="itemsss in item"
                                :key="itemsss"
                                variant="primary"
                                class="bg-aqua"
                              >
                                {{ itemsss }}
                              </b-badge>
                            </div>
                          </td>
                          <td
                            colspan="1"
                            class="text-center"
                          >
                            <button
                              class="btn-danger rounded"
                              style="width: 35px !important; height: 35px !important"
                              @click="NumArrCartInputStep1Show = [] , DelItems(index)"
                            >
                              <i class="fas fa-trash-alt" />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="section-content">
                  <div class="row">
                    <div
                      v-if="Title.LimitU3.length > 0"
                      class="col-6 col-md-6"
                    >
                      <strong>สามตัวบน</strong>
                      <ul class="list-play-detail limit-number">
                        <li
                          v-for="item in Title.LimitU3"
                          :key="item._id"
                        >
                          <div class="float-left">
                            เลข {{ item.NumLot }}
                          </div>
                          <div class="float-right">
                            <strong class="color-orange">จ่าย {{ item.Rate }}</strong>
                          </div>
                          <div class="clearfix" />
                        </li>
                      </ul>
                    </div>

                    <div
                      v-if="Title.LimitU2.length > 0"
                      class="col-6 col-md-6"
                    >
                      <strong>สองตัวบน</strong>
                      <ul class="list-play-detail limit-number">
                        <li
                          v-for="item in Title.LimitU2"
                          :key="item._id"
                        >
                          <div class="float-left">
                            เลข {{ item.NumLot }}
                          </div>
                          <div class="float-right">
                            <strong class="color-orange">จ่าย {{ item.Rate }}</strong>
                          </div>
                          <div class="clearfix" />
                        </li>
                      </ul>
                    </div>

                    <div
                      v-if="Title.LimitT3.length > 0"
                      class="col-6 col-md-6"
                    >
                      <strong>สามตัวโต้ด</strong>
                      <ul class="list-play-detail limit-number">
                        <li
                          v-for="item in Title.LimitT3"
                          :key="item._id"
                        >
                          <div class="float-left">
                            เลข {{ item.NumLot }}
                          </div>
                          <div class="float-right">
                            <strong class="color-orange">จ่าย {{ item.Rate }}</strong>
                          </div>
                          <div class="clearfix" />
                        </li>
                      </ul>
                    </div>

                    <div
                      v-if="Title.LimitD2.length > 0"
                      class="col-6 col-md-6"
                    >
                      <strong>สองตัวล่าง</strong>
                      <ul class="list-play-detail limit-number">
                        <li
                          v-for="item in Title.LimitD2"
                          :key="item._id"
                        >
                          <div class="float-left">
                            เลข {{ item.NumLot }}
                          </div>
                          <div class="float-right">
                            <strong class="color-orange">จ่าย {{ item.Rate }}</strong>
                          </div>
                          <div class="clearfix" />
                        </li>
                      </ul>
                    </div>

                    <div
                      v-if="Title.LimitD3.length > 0"
                      class="col-6 col-md-6"
                    >
                      <strong>สามตัวหน้า</strong>
                      <ul class="list-play-detail limit-number">
                        <li
                          v-for="item in Title.LimitD3"
                          :key="item._id"
                        >
                          <div class="float-left">
                            เลข {{ item.NumLot }}
                          </div>
                          <div class="float-right">
                            <strong class="color-orange">จ่าย {{ item.Rate }}</strong>
                          </div>
                          <div class="clearfix" />
                        </li>
                      </ul>
                    </div>

                    <div
                      v-if="Title.LimitB3.length > 0"
                      class="col-6 col-md-6"
                    >
                      <strong>สามตัวท้าย</strong>
                      <ul class="list-play-detail limit-number">
                        <li
                          v-for="item in Title.LimitB3"
                          :key="item._id"
                        >
                          <div class="float-left">
                            เลข {{ item.NumLot }}
                          </div>
                          <div class="float-right">
                            <strong class="color-orange">จ่าย {{ item.Rate }}</strong>
                          </div>
                          <div class="clearfix" />
                        </li>
                      </ul>
                    </div>

                    <div
                      v-if="Title.LimitU1.length > 0"
                      class="col-6 col-md-6"
                    >
                      <strong>วิ่งบน</strong>
                      <ul class="list-play-detail limit-number">
                        <li
                          v-for="item in Title.LimitU1"
                          :key="item._id"
                        >
                          <div class="float-left">
                            เลข {{ item.NumLot }}
                          </div>
                          <div class="float-right">
                            <strong class="color-orange">จ่าย {{ item.Rate }}</strong>
                          </div>
                          <div class="clearfix" />
                        </li>
                      </ul>
                    </div>

                    <div
                      v-if="Title.LimitD1.length > 0"
                      class="col-6 col-md-6"
                    >
                      <strong>วิ่งล่าง</strong>
                      <ul class="list-play-detail limit-number">
                        <li
                          v-for="item in Title.LimitD1"
                          :key="item._id"
                        >
                          <div class="float-left">
                            เลข {{ item.NumLot }}
                          </div>
                          <div class="float-right">
                            <strong class="color-orange">จ่าย {{ item.Rate }}</strong>
                          </div>
                          <div class="clearfix" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="box-name">
                  <div class="mt-2 d-flex align-items-center justify-content-center">
                    <label style="margin-top: -10px">ใส่ชื่อลูกค้า :&nbsp;</label>
                    <div class="form-group-100 form-group has-feedback">
                      <div class="input-2-box">
                        <input
                          v-model="remark"
                          type="text"
                          name="remark"
                          autocomplete="off"
                          class="form-control"
                          style="border: none; !important"
                          data-fv-field="remark"
                        ><i
                          class="form-control-feedback"
                          data-fv-icon-for="remark"
                          style="display: none;"
                        />
                        <div class="text-control">
                          ฿{{ Commas(CalSumAmount()) }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="text-center">
                    <button
                      :disabled="!remark"
                      class="btn btn-gradient-primary rounded"
                      @click="SummitBet()"
                    >
                      บันทึกข้อมูล
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>

        <!-- รายการโพย -->
        <b-col
          md="12"
          lg="5"
        >
          <b-card no-body>
            <div class="nav-text p-1 font-weight-bolder">
              รายการบิลแทงล่าสุด: <span class="text-danger">{{ NameShow }}</span>
            </div>
            <b-table
              small
              responsive
              head-variant="light"
              :fields="fieldBill"
              :items="HistoryList"
              class="mb-4 text-center"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{
                    width: field.key === 'foo' ? '120px' : '180px',
                  }"
                >
              </template>
              <template #cell(index)="data">
                {{ perPage * (currentPage- 1) + (data.index + 1) }}
              </template>

              <template #cell(actions)="data">
                <b-badge
                  v-if="data.item.list_id === '' || data.item.list_id === null"
                  :variant="'warning'"
                  class="badge-glow cursor-pointer"
                >
                  กำลังบันทึกผล
                </b-badge>
                <b-badge
                  v-else
                  :variant="
                    data.item.status === 'confirm' ? 'success' : 'danger'
                  "
                  class="badge-glow cursor-pointer"
                  @click="CancelBill(data.item)"
                >
                  {{ data.item.status === 'confirm' ? 'ยืนยัน' : 'คืนโพย' }}
                </b-badge>
              </template>
            </b-table>
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- page length -->
              <b-form-group
                label="Per Page"
                label-cols="8"
                label-align="left"
                label-size="sm"
                label-for="sortBySelect"
                class="text-nowrap mb-md-0 mr-1 ml-1 pb-1"
              >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  inline
                  :options="pageOptions"
                />
              </b-form-group>

              <!-- pagination -->
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                  @input="GetHistory()"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>

        <!-- <b-card
          v-if="Title.LottoHead && countArry > 0 && statusCheck === true && step1 === true"
          no-body
        >
          <div class="nav-text p-1 font-weight-bolder">
            รายการแทง {{ countArry }} รายการ
          </div>
          <template>
            <validation-observer ref="BetItemsRules">
              <b-table
                small
                responsive
                head-variant="light"
                :fields="fields"
                :items="items"
                class="mb-4 text-center"
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{
                      width: field.key === 'foo' ? '120px' : '180px',
                    }"
                  >
                </template>
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(price)="data">
                  <validation-provider
                    #default="{ errors }"
                    :rules="`required|between:${
                      CheckAmount(data.item.num_type).Min
                    },${CheckAmount(data.item.num_type).Max}`"
                    name="Number between"
                  >
                    <b-form-input
                      v-model="data.item.price"
                      type="number"
                      class="form-css text-center"
                      placeholder=""
                      @change="
                        CheckAmount(
                          data.item.num_type,
                          data.item.price,
                          data.index,
                          data.item.num_lot,
                        )
                      "
                    />

                    <small class="text-danger">{{
                      errors[0] ||
                        TestPrice(
                          data.item.price,
                          CheckAmount(data.item.num_type).Min,
                          CheckAmount(data.item.num_type).Max,
                        ) === true
                        ? `ระบุจำนวนเงิน ${Commas(
                          CheckAmount(data.item.num_type).Min,
                        )}-${Commas(
                          CheckAmount(data.item.num_type).Max,
                        )} บาท`
                        : ''
                    }}</small>
                  </validation-provider>
                </template>

                <template #cell(win_rate)="data">
                  <p
                    :style="`color: ${
                      data.item.RateLimit ? 'red' : 'black'
                    }`"
                    class="mt-1"
                  >
                    {{ data.item.win_rate }}
                  </p>
                </template>

                <template #cell(win)="data">
                  {{ Commas(data.item.win_rate * data.item.price) }}
                </template>

                <template #cell(delete)="data">
                  <b-button
                    variant="danger"
                    size="sm"
                    class="btn-icon"
                    style="height: 32px; width: 32px;"
                    @click="items.splice(data.index, 1) , countArry = countArry - 1"
                  >
                    <i class="fas fa-trash-alt" />
                  </b-button>
                </template>
              </b-table>
            </validation-observer>
          </template>
        </b-card> -->
        </b-col>
      </b-row>
    </b-overlay>
    <!-- modal alert -->
    <b-modal
      ref="modal-alert"
      hide-footer
      hide-header
      centered
      size="sm"
      title="Using Component Methods"
    >
      <div class="text-center">
        <b-avatar
          size="100"
          icon="AlertCircleIcon"
          class="mb-1"
          variant="warning"
        >
          <feather-icon
            icon="AlertCircleIcon"
            size="100"
          />
        </b-avatar>
        <h3>แจ้งเตือนปิดรับแทง !!</h3>
        <p>เนื่องจากเลขดังกล่าวได้ถึงยอดที่รับแทงแล้ว</p>

        <b-table
          small
          responsive="sm"
          :items="CloseBetModal"
          :fields="fieldsModal"
        />

        <b-button
          variant="warning"
          @click="showAlertHide()"
        >
          ตกลง
        </b-button>
      </div>
    </b-modal>
    <b-modal
      ref="my-modal"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <div class="text-center mt-2 mb-2">
        <b-avatar
          variant="light-success"
          size="80"
        >
          <i class="fas fa-thumbs-up fa-3x" />
        </b-avatar>

        <h3 class="font-weight-bolder text-success mt-1">
          ระบบได้ทำการส่งโพยของท่านให้เรียบร้อยแล้ว
        </h3>
      </div>
      <table
        style="width: 100%"
        class="pl-1 pr-1 mb-1"
      >
        <tbody>
          <tr>
            <td
              colspan="2"
              class="text-center"
            >
              <h4 class="font-weight-bolder">
                {{ Slip.LottoHeadName }}
              </h4>
            </td>
          </tr>
          <tr>
            <td>
              <small>วันที่</small>
              <h4 class="font-weight-bolder">
                {{ Slip.date }}
              </h4>
            </td>
            <td>
              <small>เวลา</small>
              <h4 class="font-weight-bolder">
                {{ Slip.time }} น.
              </h4>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <small>ชื่อบิล</small>
              <h4 class="font-weight-bolder">
                {{ remarkBill }}
              </h4>
            </td>
          </tr>
          <tr>
            <td>
              <small>รายการแทง</small>
              <h4 class="font-weight-bolder">
                {{ Slip.count }} รายการ
              </h4>
            </td>
            <td>
              <small>ยอดเดิมพันทั้งหมด</small>
              <h4 class="font-weight-bolder">
                {{ Slip.SumAmount }} บาท
              </h4>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="text-center">
        <div>
          <b-button
            variant="warning"
            block
            style="color: #000 !important"
            @click="hideModal"
          >
            ซื้อหวยต่อ
          </b-button>
        </div>
      </div>

    </b-modal>
  </div>
</template>

<script>
import { required, between, digits } from '@validations'
import {
  BButton,
  BRow,
  BCol,
  BModal,
  BTable,
  // BFormInput,
  BCard,
  BBadge,
  BAvatar,
  VBToggle,
  BPagination,
  BFormGroup,
  BFormSelect,
  // BFormCheckbox,
  BOverlay,
  BIconController,
} from 'bootstrap-vue'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import vSelect from 'vue-select'
import 'swiper/css/swiper.css'
import momenttz from 'moment-timezone'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import OtpInput from '@8bu/vue-otp-input'

// import SidebarContent from './SidebarContent.vue'
// import SidebarContentFooter from './SidebarContentFooter.vue'

export default {
  components: {
    BFormGroup,
    BCard,
    BButton,
    BRow,
    BCol,
    BOverlay,
    BIconController,
    BFormSelect,
    // OtpInput,
    BModal,
    BTable,
    // BFormInput,
    BBadge,
    BAvatar,
    // ValidationProvider,
    // ValidationObserver,
    BPagination,
    // BFormSelect,
    // BFormCheckbox,

    // vSelect,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      isActive: false,
      isActive1: false,
      BetType3: '',
      add_remark: '',
      remark: null,
      discount_member: 0,
      discount: null,
      UserData: JSON.parse(localStorage.getItem('userData')),
      optionsMember: '',
      member: '',
      rule: null,
      confirm: false,
      countArry: 0,
      required,
      between,
      digits,
      payOut: false,
      CheckSubmit: true,
      BugOtp: true,
      show: false,
      ShowLimit: false,
      ShowClose: false,
      fieldsModal: [
        { key: 'type', label: 'ประเภท' },
        { key: 'NumBet', label: 'เลขที่แทง' },
      ],
      // LottoList: JSON.parse(localStorage.getItem('LottoList')),
      LottoSubList: [],
      SumAmount: 0,
      AmountBet: null,
      AmountBet2: null,
      NumBet: '',
      NumArrCart: '',
      NumArrCartInput: null,
      otp: '',
      Status: 'Diposit',
      Clicked: 2,
      ShowDigit: 3,
      CheckBetType: true,
      maxlength: 3,
      // BetType: ['3ตัวบน', 'กลับตัวเลข'],
      BetType: ['3ตัวบน', '3ตัวโต๊ด'],
      Interval: null,
      IntervalRate: null,
      fields: [
        // { key: 'index', label: 'ลำดับ' },
        { key: 'num_lot', label: 'เลข' },
        { key: 'num_type', label: 'ประเภท' },
        { key: 'price', label: 'ยอด', thStyle: { width: '40%' } },
        { key: 'win_rate', label: 'จ่าย' },
        { key: 'win', label: 'ชนะ' },
        { key: 'delete', label: 'ลบ', thStyle: { width: '5%' } },
      ],
      fieldss: [
        // { key: 'index', label: 'ลำดับ' },
        { key: 'num_lot', label: 'เลข' },
        { key: 'num_type', label: 'ประเภท' },
        { key: 'price', label: 'ยอด', thStyle: { width: '40%' } },
        { key: 'win_rate', label: 'จ่าย' },
        { key: 'win', label: 'ชนะ' },
      ],
      fieldBill: [
        // { key: 'index', label: 'ลำดับที่' },
        { key: 'remark', label: 'ชื่อลูกค้า', thStyle: { width: '25%' } },
        { key: 'lotto_type', label: 'ประเภทหวย', thStyle: { width: '20%' } },
        { key: 'amount', label: 'เงินเดิมพัน', thStyle: { width: '20%' } },
        {
          key: 'created_at',
          label: 'เวลาแทง',
          formatter: value => momenttz(value).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss'),
          thStyle: { width: '20%' },
        },
        // { key: 'status', label: 'สถานะ', thStyle: { width: '15%' } },
        { key: 'actions', label: 'สถานะ', thStyle: { width: '15%' } },
      ],
      items: [],
      Historyitems: [],
      RateAll: {
        Three: {
          Up: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down_f: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down_b: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Tot: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
        },
        Two: {
          Up: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Tot: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
        },
        One: {
          Up: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
        },
      },
      MinAmount: 0,
      MaxAmount: 0,
      Title: {
        RoundID: '',
        LottoName: '',
        LottoHead: null,
        LottoSubHead: null,
        LottoDetail: [],
        CloseBet: '',
        CloseDate: '',
        CloseDateName: '',
        ShowCloseDate: '',
        LimitU3: [],
        LimitD3: [],
        LimitB3: [],
        LimitT3: [],
        LimitU2: [],
        LimitD2: [],
        LimitT2: [],
        LimitU1: [],
        LimitD1: [],
        CloseBetU3: [],
        CloseBetD3: [],
        CloseBetB3: [],
        CloseBetT3: [],
        CloseBetU2: [],
        CloseBetD2: [],
        CloseBetT2: [],
        CloseBetU1: [],
        CloseBetD1: [],
      },
      CloseBet: {
        CloseU3: [],
        CloseD3: [],
        CloseB3: [],
        CloseT3: [],
        CloseU2: [],
        CloseD2: [],
        CloseT2: [],
        CloseU1: [],
        CloseD1: [],
      },
      CloseBetModal: [],
      Slip: {
        SumAmount: null,
        date: null,
        time: null,
        listID: null,
        count: null,
        LottoHeadName: null,
      },
      statusCheck: false,
      Num_Arr: null,
      MainWallet: 0,
      NameShow: '',
      step1: false,
      NumArrCartInputStep1: '',
      NumArrCartInputStep1Show: [],
      NumArrCartInputStep2Show: [],
      NumArrCartInputStep2Cal: [],
      remarkBill: '',
      CloseTimeShow: '',
      currentPage: 1,
      perPage: 5,
      HistoryList: [],
      totalRows: 0,
      pageOptions: [5, 10, 15, 20],
    }
  },
  beforeDestroy() {
    clearInterval(this.Interval)
    clearInterval(this.IntervalTime)
  },
  created() {
    this.Interval = setInterval(() => {
      this.GetHistory()
    }, 5000)
  },
  async mounted() {
    this.GetDataLotto()
    this.ShowWallet()
    this.GetHistory()
    this.IntervalTime = setInterval(() => {
      this.ShowWallet()
      this.CloseTimeShow = this.CalDiffTime()
      if (this.CloseTimeShow === '0 วัน 00 : 00 : 00') {
        this.$router.push({ name: 'round-huay' })
      }
    }, 500)
  },
  methods: {
    CancelBill(data) {
      if (data.status === 'confirm') {
        this.$swal({
          title: 'แจ้งเตือน ?',
          text: `ยืนยันที่จะคืนโพยของคุณ ${data.remark} หรือไม่ ?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.isConfirmed) {
            this.show = true
            const Obj = {
            // eslint-disable-next-line no-underscore-dangle
              ListID: data.list_id,
            }
            this.$http
              .post(
                'https://api.rmtlotto.com/api/member/bet/refund', Obj,
              )
              .then(response => {
                this.GetHistory()
                if (response.data) {
                  this.show = false
                  this.$swal({
                    icon: 'success',
                    title: 'สำเร็จ',
                    text: 'คืนโพยสำเร็จ',
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                }
              })
              .catch(error => console.log(error))
          } else {
            this.show = false
            this.$swal('ยกเลิก', 'ยกเลิกการคืนโพย !', 'error')
          }
        })
      }
    },
    async Refund(ListID) {
      try {
        const Obj = {
          ListID,
        }
        const { data: response } = await this.$http.post(
          'https://api.rmtlotto.com/api/member/bet/refund',
          Obj,
        )
        if (response && response.success === true) {
          this.SwalMes(
            'success',
            'คืนโพยเรียบร้อย',
            `ยอดคืนโพยทั้งหมด ${this.Commas(response.Mes)} บาท`,
          )
          // this.$router.push({ name: 'list-select' })
        }
      } catch (err) {
        this.SwalMes('error', 'คืนไม่สำเร็จ', err.response.data.Mes)
        // this.$router.push({ name: 'list-select' })
      }
    },
    GetHistory() {
      const params = {
        page: this.currentPage,
        perpage: this.perPage,
        round_id: this.$route.params.round_id,
      }
      this.$http
        .post(
          'https://api.rmtlotto.com/api/member/bill',
          params,
        )
        .then(response => {
          this.onFiltered(response.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.HistoryList = filteredItems.Mes
      this.totalRows = filteredItems.total
    },
    async DelItems(index) {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.NumArrCartInputStep2Show[index]) {
        const indexx = this.items.findIndex(obj => obj.num_lot === item)
        this.items.splice(indexx, 1)
        const indexx2 = this.items.findIndex(obj => obj.num_lot === item)
        if (indexx2 !== -1) {
          this.items.splice(indexx2, 1)
          this.countArry -= 1
        }
        this.countArry -= 1
      }
      this.NumArrCartInputStep2Cal.splice(index, 1)
      this.NumArrCartInputStep2Show.splice(index, 1)
    },
    async step2() {
      if (this.step1 === true && this.NumArrCartInputStep1Show.length > 0) {
        const obj = [
          this.ShowDigit,
          Number(this.AmountBet),
          Number(this.AmountBet2),
        ]
        this.NumArrCartInputStep2Cal.push(obj)
        this.NumArrCartInputStep2Show.push(this.NumArrCartInputStep1Show)
        // this.AmountBet = null
        // this.AmountBet2 = null
        // console.log(this.NumArrCartInputStep2Show)
      }
    },
    async AddCartInputStep1(num) {
      if (num.length > 3 || this.ShowDigit === 1) {
        let ArryCart = num.replace(/\D/g, '')
        if (this.ShowDigit === 3 && num.length > 1) {
          ArryCart = ArryCart.match(/.{3}/g)
        } else if (this.ShowDigit === 2 && num.length > 1) {
          ArryCart = ArryCart.match(/.{2}/g)
        }
        // let ArryCart = num.split(' ')
        // if (ArryCart[1] === undefined && num.length > 1) {
        //   ArryCart = ArryCart[0].split('-')
        //   if (ArryCart[1] === undefined) {
        //     ArryCart = ArryCart[0].split('/')
        //     if (ArryCart[1] === undefined) {
        //       ArryCart = ArryCart[0].split(',')
        //       if (ArryCart[1] === undefined) {
        //         ArryCart = ArryCart[0].split('=')
        //         if (ArryCart[1] === undefined) {
        //           ArryCart = ArryCart[0].split('*')
        //           if (ArryCart[1] === undefined) {
        //             ArryCart = ArryCart[0].split('_')
        //             if (ArryCart[1] === undefined) {
        //               ArryCart = ArryCart[0].split('&')
        //               if (ArryCart[1] === undefined) {
        //                 ArryCart = ArryCart[0].split('.')
        //                 if (ArryCart[1] === undefined) {
        //                   ArryCart = ArryCart[0].match(/.{3}/g)
        //                 }
        //               }
        //             }
        //           }
        //         }
        //       }
        //     }
        //   }
        // }
        // console.log(ArryCart)
        // eslint-disable-next-line no-restricted-syntax
        for (const item of ArryCart) {
          this.NumBet = item
          if (this.ShowDigit === 3) {
            if (this.NumBet.length === 3) {
              // SwitchNum3 กลับตัวเลข
              if (this.BetType.indexOf('กลับตัวเลข') >= 0) {
                const x = this.SwitchNum3(this.NumBet)
                // eslint-disable-next-line no-restricted-syntax
                for (const items of x) {
                  this.NumArrCartInputStep1Show.push(items)
                }
              } else {
                this.NumArrCartInputStep1Show.push(this.NumBet)
              }
            }
          } else if (this.ShowDigit === 2) {
            if (this.NumBet.length === 2) {
              this.NumArrCartInputStep1Show.push(this.NumBet)
            }
          } else if (this.ShowDigit === 1) {
            if (this.NumBet.length === 1) {
              this.NumArrCartInputStep1Show.push(this.NumBet)
            }
          }
        }
        this.setFocusToTextBox()
      } else if (this.ShowDigit === 3 && num.length === 3) {
        // SwitchNum3 กลับตัวเลข
        if (this.BetType.indexOf('กลับตัวเลข') >= 0) {
          const x = this.SwitchNum3(num)
          // eslint-disable-next-line no-restricted-syntax
          for (const item of x) {
            this.NumArrCartInputStep1Show.push(item)
          }
        } else {
          this.NumArrCartInputStep1Show.push(num)
        }
        this.setFocusToTextBox()
      } else if (this.ShowDigit === 2) {
        if (num === ' ') {
          // console.log('space')
          // console.log(this.NumArrCartInputStep1Show)
          const ArrSw = []
          // eslint-disable-next-line no-restricted-syntax
          for (const item of this.NumArrCartInputStep1Show) {
            const Val = String(item)
            const num1 = Val.substr(0, 1)
            const num2 = Val.substr(1, 1)
            if (num1 !== num2) {
              ArrSw.push(num2 + num1)
            }
          }
          // console.log(ArrSw)
          this.NumArrCartInputStep1Show.push(...ArrSw)
          this.setFocusToTextBox()
        } else if (num.length === 1) {
          if (this.BetType.indexOf('19ประตู') >= 0) {
            const x = this.Swipe19(num)
            // eslint-disable-next-line no-restricted-syntax
            for (const item of x) {
              this.NumArrCartInputStep1Show.push(item)
            }
            this.setFocusToTextBox()
          }
        } else if (num.length === 2) {
          // console.log(num)
          const z = Number(num)
          if (z || num === '00') {
            this.NumArrCartInputStep1Show.push(num)
          }
          this.setFocusToTextBox()
        }
      }
    },
    async setFocusToTextBox() {
      await document.getElementById('InputNum').focus()
      this.NumArrCartInputStep1 = null
    },
    async AddCart(num) {
      // console.log(num)
      await this.step2()
      this.NumArrCartInputStep1Show = []
      // eslint-disable-next-line no-restricted-syntax
      for (const item of num) {
        this.NumBet = item
        // console.log(this.NumBet)
        if (this.ShowDigit === 3) {
          if (this.NumBet.length === 3) {
            this.PushDigit3()
          }
        } else if (this.ShowDigit === 2) {
          if (this.NumBet.length === 2) {
            this.PushDigit2()
          }
        } else if (this.ShowDigit === 1) {
          if (this.NumBet.length === 1) {
            this.PushDigit1()
          }
        }
      }
      this.setFocusToTextBox()
      this.AmountBet = null
      this.AmountBet2 = null
      this.NumArrCartInput = null
    },
    async GetDataLotto() {
      this.NameShow = this.$route.params.LottoName
      this.Title.LottoHead = this.$route.params.LottoHead
      this.Title.RoundID = this.$route.params.round_id
      if (this.$route.params.LottoSubHead === 'null' || this.$route.params.LottoSubHead === 'undefined') {
        this.$route.params.LottoSubHead = null
      }
      this.Title.LottoSubHead = this.$route.params.LottoSubHead
      await this.ShowTitle()
      await this.GetRate(this.Title.RoundID)
    },
    CalDiffTime() {
      const DateRound = momenttz(this.$route.params.CloseDate).tz('Asia/Bangkok')
      const DateNow = momenttz().tz('Asia/Bangkok')
      const Seconds = DateRound.diff(DateNow, 'seconds')

      const Day = Math.floor(Seconds / 86400)
      const Hours = Math.floor((Seconds % 86400) / 3600)
      const Minutes = Math.floor((Seconds % 3600) / 60)
      const numseconds = Math.floor((Seconds % 3600) % 60)
      if (Day >= 0) {
        return `${Day} วัน ${Hours < 10 ? `0${Hours}` : Hours} : ${
          Minutes < 10 ? `0${Minutes}` : Minutes
        } : ${numseconds < 10 ? `0${numseconds}` : numseconds}`
      }
      return null
    },
    showRule(data) {
      if (data === 'หวยรัฐบาล') {
        this.rule = 'หวยรัฐบาล'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยออมสิน') {
        this.rule = 'หวยออมสิน'
        this.$refs['my-rule'].show()
      } else if (data === 'หวย ธกส') {
        this.rule = 'หวย ธกส'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยหุ้นไทย (เช้า)') {
        this.rule = 'หวยหุ้นไทย'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยหุ้นไทย (เที่ยง)') {
        this.rule = 'หวยหุ้นไทย'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยหุ้นไทย (บ่าย)') {
        this.rule = 'หวยหุ้นไทย'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยหุ้นไทย (เย็น)') {
        this.rule = 'หวยหุ้นไทย'
        this.$refs['my-rule'].show()
      } else if (data === 'เยอรมัน') {
        this.rule = 'เยอรมัน'
        this.$refs['my-rule'].show()
      } else if (data === 'อังกฤษ') {
        this.rule = 'อังกฤษ'
        this.$refs['my-rule'].show()
      } else if (data === 'ลาว') {
        this.rule = 'ลาว'
        this.$refs['my-rule'].show()
      } else if (data === 'หุ้นนิเคอิ (เช้า)') {
        this.rule = 'หุ้นนิเคอิ'
        this.$refs['my-rule'].show()
      } else if (data === 'หุ้นนิเคอิ (บ่าย)') {
        this.rule = 'หุ้นนิเคอิ'
        this.$refs['my-rule'].show()
      } else if (data === 'สิงคโปร์') {
        this.rule = 'สิงคโปร์'
        this.$refs['my-rule'].show()
      } else if (data === 'ไต้หวัน') {
        this.rule = 'ไต้หวัน'
        this.$refs['my-rule'].show()
      } else if (data === ' จีน (เช้า)') {
        this.rule = 'จีน'
        this.$refs['my-rule'].show()
      } else if (data === ' จีน (บ่าย)') {
        this.rule = 'จีน'
        this.$refs['my-rule'].show()
      } else if (data === 'อินเดีย') {
        this.rule = 'อินเดีย'
        this.$refs['my-rule'].show()
      } else if (data === 'รัสเซีย') {
        this.rule = 'รัสเซีย'
        this.$refs['my-rule'].show()
      } else if (data === 'ฮานอย') {
        this.rule = 'ฮานอย'
        this.$refs['my-rule'].show()
      } else if (data === 'ฮั่งเส็ง (เช้า)') {
        this.rule = 'ฮั่งเส็ง'
        this.$refs['my-rule'].show()
      } else if (data === 'ฮั่งเส็ง (บ่าย)') {
        this.rule = 'ฮั่งเส็ง'
        this.$refs['my-rule'].show()
      } else if (data === 'มาเลย์') {
        this.rule = 'มาเลย์'
        this.$refs['my-rule'].show()
      } else if (data === 'อียิปต์') {
        this.rule = 'อียิปต์'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยยี่กี') {
        this.rule = 'หวยยี่กี'
        this.$refs['my-rule'].show()
      } else if (data === 'หุ้นเกาหลี') {
        this.rule = 'หุ้นเกาหลี'
        this.$refs['my-rule'].show()
      }
    },
    showAlertHide() {
      this.$refs['modal-alert'].hide()
    },
    async SummitBet() {
      // console.log(this.items)
      const CheckPrice = this.items.some(el => el.price === '')
      if (CheckPrice === false) {
        this.CheckSubmit = false
        if (
          this.SumAmount > this.MainWallet
        ) {
          this.SwalMes(
            'warning',
            'จำนวนเงินไม่เพียงพอ !!',
            'ยอดเงินของท่านไม่เพียงพอกรุณาฝากเงินก่อนค่ะ',
          )
          // this.items.splice(this.items.length - 1, 1)
        } else {
          this.show = true
          const Obj = {
            SumAmount: this.SumAmount,
            discount: this.discount,
            discount_remark: this.discount_member,
            UserData: null,
            remark: this.remark,
            OBJ: this.items,
          }
          // console.log(Obj)
          try {
            const { data: response } = await this.$http.post(
              'https://api.rmtlotto.com/api/member/bet/store',
              Obj,
            )
            if (response) {
              await this.GetHistory()
              this.items = []
              this.CheckSubmit = true
              this.confirm = false
              this.countArry = 0
              this.Slip.SumAmount = null
              this.Slip.date = null
              this.Slip.time = null
              this.Slip.listID = null
              this.Slip.count = null
              this.Slip.LottoHeadName = null
              this.remark = null
              this.NumArrCartInputStep1 = ''
              this.NumArrCartInputStep1Show = []
              this.NumArrCartInputStep2Show = []
              this.NumArrCartInputStep2Cal = []
              this.show = false
              this.ShowWallet()
              this.ShowTitle()
              this.GetRate(this.Title.RoundID)
              // await this.showModal(response)
              if (response.success === true) {
                // console.log(response)
                // await this.sleep(3000)
                // window.location.reload()
              } else {
                // this.showAlert(response.Mes)
              }
            }
          } catch (err) {
            this.SwalMes('error', 'ส่งโพยไม่สำเร็จ', 'ไม่สามารถส่งโพยได้ในขณะนี้')
            // console.log(err)
            this.CheckSubmit = true
          }
        }
      }
    },
    async sleep(ms) {
      return new Promise(resolve => {
        setTimeout(resolve, ms)
      })
    },
    async ShowWallet() {
      this.MainWallet = this.$store.state.appConfig.UserData.Balance
      this.discount = this.$store.state.appConfig.UserData.Discount
    },
    async ShowTitle() {
      if (this.Title.LottoDetail.u3 || this.Title.LottoDetail.d3 || this.Title.LottoDetail.b3 || this.Title.LottoDetail.t3) {
        this.ShowDigit = 3
      } else if (this.Title.LottoDetail.u2 || this.Title.LottoDetail.d2 || this.Title.LottoDetail.t2) {
        this.ShowDigit = 2
      } else if (this.Title.LottoDetail.u1 || this.Title.LottoDetail.d1) {
        this.ShowDigit = 1
      }
      const params = {
        RoundID: this.Title.RoundID,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.rmtlotto.com/api/member/round/ShowByHeadAndSub/Buynow',
          { params },
        )
        if (ResData.success) {
          this.statusCheck = true
          // eslint-disable-next-line no-underscore-dangle
          this.Title.LottoTypeHead = ResData.mes.LottoTypeHead
          this.Title.CloseBet = ResData.mes.StopBetTime
          this.Title.CloseDate = ResData.mes.CloseDate
          this.Title.CloseDateName = ResData.mes.CloseDateName
          this.statusCheck = true
        }
      } catch (e) {
        this.statusCheck = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'แจ้งเตือน',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: 'ไม่พบรอบที่เปิดรับแทง',
          },
        })
        // console.log(e)
      }
    },
    async GetRate(RoundID) {
      const params = {
        RoundID,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.rmtlotto.com/api/member/ratebyround/showbetmore',
          { params },
        )
        if (ResData.success) {
          // console.log(ResData.mes)
          const Rate = ResData.mes.Data
          const ListBet = ResData.mes.LimitData
          // const ListBet = {
          //   B3: [],
          //   D1: [],
          //   D2: [],
          //   D3: [],
          //   T2: [],
          //   T3: [],
          //   U1: [],
          //   U2: [],
          //   U3: [],
          // }
          this.RateAll.Three.Up.Min = Rate.MinU3
          this.RateAll.Three.Down_f.Min = Rate.MinD3
          this.RateAll.Three.Down_b.Min = Rate.MinB3
          this.RateAll.Three.Tot.Min = Rate.MinT3
          this.RateAll.Two.Up.Min = Rate.MinU2
          this.RateAll.Two.Down.Min = Rate.MinD2
          this.RateAll.Two.Tot.Min = Rate.MinT2
          this.RateAll.One.Up.Min = Rate.MinU1
          this.RateAll.One.Down.Min = Rate.MinD1

          this.RateAll.Three.Up.Max = Rate.MaxU3
          this.RateAll.Three.Down_f.Max = Rate.MaxD3
          this.RateAll.Three.Down_b.Max = Rate.MaxB3
          this.RateAll.Three.Tot.Max = Rate.MaxT3
          this.RateAll.Two.Up.Max = Rate.MaxU2
          this.RateAll.Two.Down.Max = Rate.MaxD2
          this.RateAll.Two.Tot.Max = Rate.MaxT2
          this.RateAll.One.Up.Max = Rate.MaxU1
          this.RateAll.One.Down.Max = Rate.MaxD1

          this.RateAll.Three.Up.Rate = Rate.U3
          this.RateAll.Three.Down_f.Rate = Rate.D3
          this.RateAll.Three.Down_b.Rate = Rate.B3
          this.RateAll.Three.Tot.Rate = Rate.T3
          this.RateAll.Two.Up.Rate = Rate.U2
          this.RateAll.Two.Down.Rate = Rate.D2
          this.RateAll.Two.Tot.Rate = Rate.T2
          this.RateAll.One.Up.Rate = Rate.U1
          this.RateAll.One.Down.Rate = Rate.D1

          this.RateAll.Three.Up.BetLimit = Rate.BetLimitU3
          this.RateAll.Three.Down_f.BetLimit = Rate.BetLimitD3
          this.RateAll.Three.Down_b.BetLimit = Rate.BetLimitB3
          this.RateAll.Three.Tot.BetLimit = Rate.BetLimitT3
          this.RateAll.Two.Up.BetLimit = Rate.BetLimitU2
          this.RateAll.Two.Down.BetLimit = Rate.BetLimitD2
          this.RateAll.Two.Tot.BetLimit = Rate.BetLimitT2
          this.RateAll.One.Up.BetLimit = Rate.BetLimitU1
          this.RateAll.One.Down.BetLimit = Rate.BetLimitD1

          this.RateAll.Three.Up.BetList = ListBet.U3
          this.RateAll.Three.Down_f.BetList = ListBet.D3
          this.RateAll.Three.Down_b.BetList = ListBet.B3
          this.RateAll.Three.Tot.BetList = ListBet.T3
          this.RateAll.Two.Up.BetList = ListBet.U2
          this.RateAll.Two.Down.BetList = ListBet.D2
          this.RateAll.Two.Tot.BetList = ListBet.T2
          this.RateAll.One.Up.BetList = ListBet.U1
          this.RateAll.One.Down.BetList = ListBet.D1
          this.Title.CloseBetU3 = Rate.CloseBetU3.map(x => x.NumLot)
          this.Title.CloseBetD3 = Rate.CloseBetD3.map(x => x.NumLot)
          this.Title.CloseBetB3 = Rate.CloseBetB3.map(x => x.NumLot)
          this.Title.CloseBetT3 = Rate.CloseBetT3.map(x => x.NumSort)
          this.Title.CloseBetU2 = Rate.CloseBetU2.map(x => x.NumLot)
          this.Title.CloseBetD2 = Rate.CloseBetD2.map(x => x.NumLot)
          this.Title.CloseBetT2 = Rate.CloseBetT2.map(x => x.NumSort)
          this.Title.CloseBetU1 = Rate.CloseBetU1.map(x => x.NumLot)
          this.Title.CloseBetD1 = Rate.CloseBetD1.map(x => x.NumLot)
          this.Title.LimitU3 = Rate.LimitU3.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitD3 = Rate.LimitD3.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitB3 = Rate.LimitB3.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitT3 = Rate.LimitT3.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitU2 = Rate.LimitU2.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitD2 = Rate.LimitD2.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitT2 = Rate.LimitT2.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitU1 = Rate.LimitU1.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitD1 = Rate.LimitD1.sort(
            (a, b) => a.NumLot - b.NumLot,
          )

          this.CloseBet.CloseU3 = this.CalCloseBet(
            ListBet.U3,
            this.Title.CloseBetU3,
            Rate.BetLimitU3,
          )
          this.CloseBet.CloseD3 = this.CalCloseBet(
            ListBet.D3,
            this.Title.CloseBetD3,
            Rate.BetLimitD3,
          )
          this.CloseBet.CloseB3 = this.CalCloseBet(
            ListBet.B3,
            this.Title.CloseBetB3,
            Rate.BetLimitB3,
          )
          this.CloseBet.CloseT3 = this.CalCloseBet(
            ListBet.T3,
            this.Title.CloseBetT3,
            Rate.BetLimitT3,
          )
          this.CloseBet.CloseU2 = this.CalCloseBet(
            ListBet.U2,
            this.Title.CloseBetU2,
            Rate.BetLimitU2,
          )
          this.CloseBet.CloseD2 = this.CalCloseBet(
            ListBet.D2,
            this.Title.CloseBetD2,
            Rate.BetLimitD2,
          )
          this.CloseBet.CloseT2 = this.CalCloseBet(
            ListBet.T2,
            this.Title.CloseBetT2,
            Rate.BetLimitT2,
          )
          this.CloseBet.CloseU1 = this.CalCloseBet(
            ListBet.U1,
            this.Title.CloseBetU1,
            Rate.BetLimitU1,
          )
          this.CloseBet.CloseD1 = this.CalCloseBet(
            ListBet.D1,
            this.Title.CloseBetD1,
            Rate.BetLimitD1,
          )

          this.Title.LimitU3 = this.CalLimit(
            this.Title.LimitU3,
            this.CloseBet.CloseU3,
          )
          this.Title.LimitD3 = this.CalLimit(
            this.Title.LimitD3,
            this.CloseBet.CloseD3,
          )
          this.Title.LimitB3 = this.CalLimit(
            this.Title.LimitB3,
            this.CloseBet.CloseB3,
          )
          this.Title.LimitT3 = this.CalLimit(
            this.Title.LimitT3,
            this.CloseBet.CloseT3,
          )
          this.Title.LimitU2 = this.CalLimit(
            this.Title.LimitU2,
            this.CloseBet.CloseU2,
          )
          this.Title.LimitD2 = this.CalLimit(
            this.Title.LimitD2,
            this.CloseBet.CloseD2,
          )
          this.Title.LimitT2 = this.CalLimit(
            this.Title.LimitT2,
            this.CloseBet.CloseT2,
          )
          this.Title.LimitU1 = this.CalLimit(
            this.Title.LimitU1,
            this.CloseBet.CloseU1,
          )
          this.Title.LimitD1 = this.CalLimit(
            this.Title.LimitD1,
            this.CloseBet.CloseD1,
          )
        }
      } catch (e) {
        console.log(e)
      }
    },
    CompleteNumBet() {
      if (this.ShowDigit === 3) {
        this.PushDigit3()
      } else if (this.ShowDigit === 2) {
        this.PushDigit2()
      } else if (this.ShowDigit === 1) {
        this.PushDigit1()
      }
    },
    async PushDigit3() {
      // const Swap = this.BetType.indexOf('กลับตัวเลข')
      const UP3 = this.BetType.indexOf('3ตัวบน')
      const DOWN_F3 = this.BetType.indexOf('3ตัวหน้า')
      const DOWN_B3 = this.BetType.indexOf('3ตัวท้าย')
      const TOT = this.BetType.indexOf('3ตัวโต๊ด')
      // eslint-disable-next-line no-nested-ternary
      if (TOT >= 0 || UP3 >= 0 || DOWN_F3 >= 0 || DOWN_B3 >= 0) {
        if (TOT >= 0 && this.AmountBet2 > 0) {
          this.PushItems(
            this.NumBet,
            '3ตัวโต๊ด',
            3,
            'TOT',
            this.AmountBet2,
          )
        }
        if (UP3 >= 0 && this.AmountBet > 0) {
          this.PushItems(this.NumBet, '3ตัวบน', 3, 'UP', this.AmountBet)
        }
        if (DOWN_F3 >= 0) {
          this.PushItems(
            this.NumBet,
            '3ตัวหน้า',
            3,
            'DOWN_F',
            this.AmountBet,
          )
        }
        if (DOWN_B3 >= 0) {
          this.PushItems(
            this.NumBet,
            '3ตัวท้าย',
            3,
            'DOWN_B',
            this.AmountBet,
          )
        }
      }
      this.ClearAfterBet()
      this.PushHistory(this.items)
    },
    async PushDigit2() {
      // console.log(this.BetType)
      const Up = this.BetType.indexOf('2ตัวบน') >= 0
      const Down = this.BetType.indexOf('2ตัวล่าง') >= 0
      const Tot = this.BetType.indexOf('2ตัวโต๊ด(บน)') >= 0

      if (Up && this.AmountBet > 0) {
        this.PushItems(this.NumBet, '2ตัวบน', 2, 'UP', this.AmountBet)
      }
      if (Down && this.AmountBet2 > 0) {
        this.PushItems(this.NumBet, '2ตัวล่าง', 2, 'DOWN', this.AmountBet2)
      }
      if (Tot) {
        this.PushItems(this.NumBet, '2ตัวโต๊ด(บน)', 2, 'TOT', this.AmountBet)
      }
      this.ClearAfterBet()
      this.PushHistory(this.items)
    },
    async PushDigit1() {
      const Up = this.BetType.indexOf('วิ่งบน') >= 0
      const Down = this.BetType.indexOf('วิ่งล่าง') >= 0

      if (Up && this.AmountBet > 0) {
        this.PushItems(this.NumBet, 'วิ่งบน', 1, 'UP', this.AmountBet)
      }
      if (Down && this.AmountBet2 > 0) {
        this.PushItems(this.NumBet, 'วิ่งล่าง', 1, 'DOWN', this.AmountBet2)
      }

      this.ClearAfterBet()
      this.PushHistory(this.items)
    },
    async PushItems(NumBet, NumType, BetDigit, BetType, Price) {
      // console.log(NumBet, NumType, BetDigit, BetType, Price)

      // eslint-disable-next-line
      new Promise((resolve, reject) => {
        const { Rate, RateLimit } = this.CalRate(NumBet, BetDigit, BetType)
        let NumSort = null
        if (BetType === 'TOT') {
          const NumArr = NumBet.split('')
          NumArr.sort()
          NumSort = NumArr.join('')
          // var CheckDup = this.items.findIndex(
          //   obj => obj.NumSort === NumSort
          //                   && obj.bet_digit === BetDigit
          //                   && obj.bet_type === BetType,
          // )
        } else {
          // var CheckDup = this.items.findIndex(
          //   obj => obj.num_lot === NumBet
          //                   && obj.bet_digit === BetDigit
          //                   && obj.bet_type === BetType,
          // )
        }

        // const CheckLimit = this.CheckBetLimit(
        //   NumBet,
        //   BetDigit,
        //   BetType,
        //   Number(Price),
        // )
        if (RateLimit) {
          this.SwalMes('info', 'หมายเลขจ่ายตาม %', `${NumType} หมายเลข ${NumBet} จ่าย ${Rate} บาท`)
        }
        const ObjItems = {
          num_lot: NumBet,
          num_type: NumType,
          bet_digit: BetDigit, // 1, 2 ,3
          bet_type: BetType, // TOT , UP , DOWN
          price: Price,
          round_id: this.Title.RoundID,
          LottoHead: this.Title.LottoHead,
          LottoSubHead: this.Title.LottoSubHead
            ? this.Title.LottoSubHead
            : null,
          win_rate: Rate,
          NumSort,
          RateLimit,
        }
        this.items.push(ObjItems)
        resolve()
        // if (CheckLimit.status === true) {
        //   if (RateLimit) {
        //     this.SwalMes('info', 'หมายเลขจ่ายตาม %', `${NumType} หมายเลข ${NumBet} จ่าย ${Rate} บาท`)
        //   }
        //   const ObjItems = {
        //     num_lot: NumBet,
        //     num_type: NumType,
        //     bet_digit: BetDigit, // 1, 2 ,3
        //     bet_type: BetType, // TOT , UP , DOWN
        //     price: Price,
        //     round_id: this.Title.RoundID,
        //     LottoHead: this.Title.LottoHead,
        //     LottoSubHead: this.Title.LottoSubHead
        //       ? this.Title.LottoSubHead
        //       : null,
        //     win_rate: Rate,
        //     NumSort,
        //     RateLimit,
        //   }
        //   this.items.push(ObjItems)
        //   resolve()
        // } else if (CheckLimit.amount === 0) {
        //   this.SwalMes('error', 'ปิดรับแทง', `${NumType} หมายเลข ${NumBet}`)
        //   reject()
        // } else {
        //   this.SwalMes(
        //     'error',
        //     'ไม่สำเร็จ !',
        //     `${NumType} หมายเลข ${NumBet} รับแทงไม่เกิน ${this.Commas(
        //       CheckLimit.amount,
        //     )} บาท`,
        //   )
        //   reject()
        // }
      })
    },
    async PushHistory(Val) {
      this.countArry = Val.length
      // const St = JSON.stringify(Val)
      // this.Historyitems.push(JSON.parse(St))
    },
    CalCloseBet(Arr, CloseList, BetLimit) {
      // console.log(Arr, CloseList, BetLimit)
      let arrArr = Arr.filter(ele => ele.totalAmount >= BetLimit)
      // eslint-disable-next-line no-underscore-dangle
      arrArr = arrArr.map(x => x._id)
      if (CloseList.length > 0) {
        arrArr.push(...CloseList)
      }
      return arrArr.sort()
    },
    CalRate(NumBet, BetDigit, BetType) {
      let Rate = 0
      let IndexLimit = null
      let RateLimit = false
      if (BetDigit === 1) {
        if (BetType === 'UP') {
          IndexLimit = this.Title.LimitU1.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitU1[IndexLimit].Rate
          } else {
            Rate = this.RateAll.One.Up.Rate
          }
        } else if (BetType === 'DOWN') {
          IndexLimit = this.Title.LimitD1.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitD1[IndexLimit].Rate
          } else {
            Rate = this.RateAll.One.Down.Rate
          }
        }
      } else if (BetDigit === 2) {
        if (BetType === 'UP') {
          IndexLimit = this.Title.LimitU2.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitU2[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Two.Up.Rate
          }
        } else if (BetType === 'DOWN') {
          IndexLimit = this.Title.LimitD2.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitD2[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Two.Down.Rate
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          IndexLimit = this.Title.LimitT3.findIndex(
            ({ NumSort }) => NumSort === SortBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitT2[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Two.Tot.Rate
          }
        }
      } else if (BetDigit === 3) {
        if (BetType === 'UP') {
          IndexLimit = this.Title.LimitU3.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitU3[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Three.Up.Rate
          }
        } else if (BetType === 'DOWN_F') {
          IndexLimit = this.Title.LimitD3.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitD3[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Three.Down_f.Rate
          }
        } else if (BetType === 'DOWN_B') {
          IndexLimit = this.Title.LimitB3.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitB3[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Three.Down_b.Rate
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          IndexLimit = this.Title.LimitT3.findIndex(
            ({ NumSort }) => NumSort === SortBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitT3[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Three.Tot.Rate
          }
        }
      }
      if (IndexLimit >= 0) {
        RateLimit = true
      }
      return { Rate, RateLimit }
    },
    CalLimit(Limit, CloseBet) {
      return Limit.filter(val => !CloseBet.includes(val.NumLot))
    },
    SelectDigit(Val) {
      this.NumBet = ''
      this.BetType = []
      this.CheckBetType = true
      this.maxlength = Val
      this.ShowDigit = Val
      this.BugOtp = false
      if (Val === 3) {
        this.BetType = ['3ตัวบน', '3ตัวโต๊ด']
      } else if (Val === 2) {
        this.BetType = ['2ตัวบน', '2ตัวล่าง']
      } else if (Val === 1) {
        this.BetType = ['วิ่งบน', 'วิ่งล่าง']
      }
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.BugOtp = true), 10)
    },
    ClearAfterBet() {
      this.NumBet = ''
      // this.AmountBet = 1
      // this.BugOtp = false
      // // eslint-disable-next-line no-return-assign
      // setTimeout(() => (this.BugOtp = true), 10)
    },
    SelectBetType3(Val) {
      this.SelectUnDup(this.BetType, Val)
      if (Val === 'กลับตัวเลข') {
        this.CheckRemove(this.BetType, '3ตัวโต๊ด')
      }
      if (Val === '3ตัวโต๊ด') {
        this.CheckRemove(this.BetType, 'กลับตัวเลข')
      }
      this.CheckBetDisable(this.BetType, '3ตัวบน', '3ตัวโต๊ด', '3ตัวหน้า', '3ตัวท้าย')
    },
    async SelectBetType2(Val) {
      // eslint-disable-next-line no-nested-ternary
      const NumType = this.BetType.indexOf('2ตัวบน') >= 0
        ? '2ตัวบน'
        : this.BetType.indexOf('2ตัวล่าง') >= 0
          ? '2ตัวล่าง'
          : '2ตัวโต๊ด(บน)'
      // eslint-disable-next-line no-nested-ternary
      const NumTypePrefix = this.BetType.indexOf('2ตัวบน') >= 0
        ? 'UP'
        : this.BetType.indexOf('2ตัวล่าง') >= 0
          ? 'DOWN'
          : 'TOT'
      if (Val === 'สองตัวคี่') {
        const Res = this.SwipeOdd()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
        this.PushHistory(this.items)
      } else if (Val === 'สองตัวคู่') {
        const Res = this.SwipeEven()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
        this.PushHistory(this.items)
      } else if (Val === 'สองตัวสูง') {
        const Res = this.SwipeHigh()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
        this.PushHistory(this.items)
      } else if (Val === 'สองตัวต่ำ') {
        const Res = this.SwipeLow()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
        this.PushHistory(this.items)
      } else {
        this.SelectUnDup(this.BetType, Val)
        if (Val === '2ตัวบน') {
          this.maxlength = 2
          // this.CheckRemove(this.BetType, '2ตัวล่าง')
          this.CheckRemove(this.BetType, '2ตัวโต๊ด(บน)')
        } else if (Val === '2ตัวล่าง') {
          this.maxlength = 2
          // this.CheckRemove(this.BetType, '2ตัวบน')
          // this.CheckRemove(this.BetType, '2ตัวโต๊ด(บน)')
        } else if (Val === '2ตัวโต๊ด(บน)') {
          this.maxlength = 2
          this.CheckRemove(this.BetType, '2ตัวบน')
          this.CheckRemove(this.BetType, '2ตัวล่าง')
        } else if (Val === '19ประตู') {
          this.maxlength = 1
          this.CheckRemove(this.BetType, 'รูดหน้า')
          this.CheckRemove(this.BetType, 'รูดหลัง')
          this.CheckRemove(this.BetType, 'กลับตัวเลข')
          this.CheckRemove(this.BetType, 'สองตัวคี่')
          this.CheckRemove(this.BetType, 'สองตัวคู่')
          this.CheckRemove(this.BetType, 'สองตัวสูง')
          this.CheckRemove(this.BetType, 'สองตัวต่ำ')
        } else if (Val === 'รูดหน้า') {
          this.maxlength = 1
          this.CheckRemove(this.BetType, '19ประตู')
          this.CheckRemove(this.BetType, 'รูดหลัง')
          this.CheckRemove(this.BetType, 'กลับตัวเลข')
          this.CheckRemove(this.BetType, 'สองตัวคี่')
          this.CheckRemove(this.BetType, 'สองตัวคู่')
          this.CheckRemove(this.BetType, 'สองตัวสูง')
          this.CheckRemove(this.BetType, 'สองตัวต่ำ')
        } else if (Val === 'รูดหลัง') {
          this.maxlength = 1
          this.CheckRemove(this.BetType, '19ประตู')
          this.CheckRemove(this.BetType, 'รูดหน้า')
          this.CheckRemove(this.BetType, 'กลับตัวเลข')
          this.CheckRemove(this.BetType, 'สองตัวคี่')
          this.CheckRemove(this.BetType, 'สองตัวคู่')
          this.CheckRemove(this.BetType, 'สองตัวสูง')
          this.CheckRemove(this.BetType, 'สองตัวต่ำ')
        } else if (Val === 'กลับตัวเลข') {
          this.CheckRemove(this.BetType, '19ประตู')
          this.CheckRemove(this.BetType, 'รูดหน้า')
          this.CheckRemove(this.BetType, 'รูดหลัง')
          this.CheckRemove(this.BetType, 'สองตัวคี่')
          this.CheckRemove(this.BetType, 'สองตัวคู่')
          this.CheckRemove(this.BetType, 'สองตัวสูง')
          this.CheckRemove(this.BetType, 'สองตัวต่ำ')
        }
        this.CheckBetDisable(this.BetType, '2ตัวบน', '2ตัวล่าง', '2ตัวโต๊ด(บน)')
      }
    },
    SelectBetType1(Val) {
      // this.BetType = []
      this.SelectUnDup(this.BetType, Val)
      // this.CheckBetDisable(this.BetType, 'วิ่งบน', 'วิ่งล่าง')
      const Index1 = this.BetType.indexOf('วิ่งบน')
      const Index2 = this.BetType.indexOf('วิ่งล่าง')
      if (Index1 >= 0 || Index2 >= 0) {
        this.CheckBetType = false
      } else {
        this.CheckBetType = true
      }
    },
    CheckRemove(Arr, Val) {
      const Index = Arr.indexOf(Val)
      if (Index >= 0) {
        Arr.splice(Index, 1)
      }
    },
    SelectUnDup(Arr, Val) {
      const Index = Arr.indexOf(Val)
      if (Index < 0) {
        Arr.push(Val)
        const { Min, Max } = this.CheckAmount(Val)
        if (Max !== 0) {
          this.MinAmount = Min
          this.MaxAmount = Max
        }
      } else {
        Arr.splice(Index, 1)
      }
    },
    CheckBetDisable(Arr, Val1, Val2, Val3, Val4) {
      const Index1 = Arr.indexOf(Val1)
      const Index2 = Arr.indexOf(Val2)
      const Index3 = Arr.indexOf(Val3)
      const Index4 = Arr.indexOf(Val4)
      if (Index1 >= 0 || Index2 >= 0 || Index3 >= 0 || Index4 >= 0) {
        this.CheckBetType = false
      } else {
        this.CheckBetType = true
      }
    },
    TestPrice(Price, Min, Max) {
      if (Price < Min || Price > Max) {
        return true
      }
      return false
    },
    CheckAmount(Val, Price, Index, NumBet = null) {
      const MinMax = { Min: 0, Max: 0 }
      let BetDigit = 3
      let BetType = 'UP'
      if (Val === '3ตัวบน') {
        MinMax.Min = this.RateAll.Three.Up.Min
        MinMax.Max = this.RateAll.Three.Up.Max
      } else if (Val === '3ตัวหน้า') {
        MinMax.Min = this.RateAll.Three.Down_f.Min
        MinMax.Max = this.RateAll.Three.Down_f.Max
        BetType = 'DOWN_F'
      } else if (Val === '3ตัวท้าย') {
        MinMax.Min = this.RateAll.Three.Down_b.Min
        MinMax.Max = this.RateAll.Three.Down_b.Max
        BetType = 'DOWN_B'
      } else if (Val === '3ตัวโต๊ด') {
        MinMax.Min = this.RateAll.Three.Tot.Min
        MinMax.Max = this.RateAll.Three.Tot.Max
        BetType = 'TOT'
      } else if (Val === '2ตัวบน') {
        MinMax.Min = this.RateAll.Two.Up.Min
        MinMax.Max = this.RateAll.Two.Up.Max
        BetDigit = 2
        BetType = 'UP'
      } else if (Val === '2ตัวล่าง') {
        MinMax.Min = this.RateAll.Two.Down.Min
        MinMax.Max = this.RateAll.Two.Down.Max
        BetDigit = 2
        BetType = 'DOWN'
      } else if (Val === '2ตัวโต๊ด(บน)') {
        MinMax.Min = this.RateAll.Two.Tot.Min
        MinMax.Max = this.RateAll.Two.Tot.Max
        BetDigit = 2
        BetType = 'TOT'
      } else if (Val === 'วิ่งบน') {
        MinMax.Min = this.RateAll.One.Up.Min
        MinMax.Max = this.RateAll.One.Up.Max
        BetDigit = 1
        BetType = 'UP'
      } else if (Val === 'วิ่งล่าง') {
        MinMax.Min = this.RateAll.One.Down.Min
        MinMax.Max = this.RateAll.One.Down.Max
        BetDigit = 1
        BetType = 'DOWN'
      }
      if (Price) {
        if (Price < MinMax.Min) {
          this.items[Index].price = MinMax.Min
        } else if (Price > MinMax.Max) {
          this.items[Index].price = MinMax.Max
        }
        const CheckLimit = this.CheckBetLimit(
          NumBet,
          BetDigit,
          BetType,
          Number(Price),
        )
        if (CheckLimit.status === false) {
          this.items[Index].price = CheckLimit.amount > 0 ? CheckLimit.amount : 0
        }
      }
      return MinMax
    },
    CheckBetLimit(NumBet, BetDigit, BetType, Price) {
      let TotalAmount = Price
      let SumAmount = 0
      if (BetDigit === 1) {
        if (BetType === 'UP') {
          const CloseIn = this.CloseBet.CloseU1.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.One.Up.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.One.Up.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.One.Up.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'DOWN') {
          const CloseIn = this.CloseBet.CloseD1.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.One.Down.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.One.Down.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.One.Down.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        }
      } else if (BetDigit === 2) {
        if (BetType === 'UP') {
          const CloseIn = this.CloseBet.CloseU2.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Two.Up.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Two.Up.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Two.Up.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'DOWN') {
          const CloseIn = this.CloseBet.CloseD2.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Two.Down.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Two.Down.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Two.Down.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          const CloseIn = this.CloseBet.CloseT2.indexOf(SortBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Two.Tot.BetList.findIndex(
            ({ _id }) => _id === SortBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Two.Tot.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Two.Tot.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        }
      } else if (BetDigit === 3) {
        if (BetType === 'UP') {
          const CloseIn = this.CloseBet.CloseU3.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Three.Up.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Three.Up.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Three.Up.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'DOWN_F') {
          const CloseIn = this.CloseBet.CloseD3.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Three.Down_f.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Three.Down_f.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Three.Down_f.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'DOWN_B') {
          const CloseIn = this.CloseBet.CloseB3.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Three.Down_b.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Three.Down_b.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Three.Down_b.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          const CloseIn = this.CloseBet.CloseT3.indexOf(SortBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Three.Tot.BetList.findIndex(
            ({ _id }) => _id === SortBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Three.Tot.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Three.Tot.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        }
      }
      return { status: true }
    },
    // 3Digit
    SwitchNum3(textnum) {
      const ReArr = []
      const num1 = textnum.substr(0, 1)
      const num2 = textnum.substr(1, 1)
      const num3 = textnum.substr(2, 1)
      if (num1 === num2 || num1 === num3 || num2 === num3) {
        if (num1 === num2) {
          ReArr.push(num1 + num1 + num3, num1 + num3 + num1, num3 + num1 + num1)
        } else if (num1 === num3) {
          ReArr.push(num1 + num2 + num1, num1 + num1 + num2, num2 + num1 + num1)
        } else {
          ReArr.push(num1 + num2 + num2, num2 + num2 + num1, num2 + num1 + num2)
        }
      } else {
        ReArr.push(
          num1 + num2 + num3,
          num1 + num3 + num2,
          num2 + num1 + num3,
          num2 + num3 + num1,
          num3 + num1 + num2,
          num3 + num2 + num1,
        )
      }
      return ReArr
    },
    // 2Digit
    SwitchNum2(textnum) {
      const ReArr = []
      const Val = String(textnum)
      const num1 = Val.substr(0, 1)
      const num2 = Val.substr(1, 1)
      if (num1 === num2) {
        ReArr.push(num1 + num2)
      } else {
        ReArr.push(num1 + num2, num2 + num1)
      }
      return ReArr
    },
    SwitchNum2New(textnum) {
      // const ReArr = []
      const Val = String(textnum)
      const num1 = Val.substr(0, 1)
      const num2 = Val.substr(1, 1)
      if (num1 === num2) {
        // this.NumArrCartInputStep1Show.push(num1 + num2)
      } else {
        this.NumArrCartInputStep1Show.push(num2 + num1)
      }
      // return ReArr
    },
    SwipeFront(Val) {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        const R1 = String(Val) + i
        ReArr.push(R1)
      }
      return ReArr
    },
    SwipeBack(Val) {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        const R1 = i + String(Val)
        ReArr.push(R1)
      }
      return ReArr
    },
    Swipe19(Val) {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        const R1 = i + String(Val)
        ReArr.push(R1)
      }
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        if (i === Number(Val)) {
          // eslint-disable-next-line no-plusplus
          i++
        }
        if (i !== 10) {
          const R2 = String(Val) + i
          ReArr.push(R2)
        }
      }
      return ReArr
    },
    SwipeLow() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 50; i++) {
        if (i < 10) {
          ReArr.push(`0${i}`)
        } else {
          ReArr.push(String(i))
        }
      }
      return ReArr
    },
    SwipeHigh() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 50; i < 100; i++) {
        if (i < 10) {
          ReArr.push(`0${i}`)
        } else {
          ReArr.push(String(i))
        }
      }
      return ReArr
    },
    SwipeOdd() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 100; i++) {
        if (i % 2) {
          if (i < 10) {
            ReArr.push(`0${i}`)
          } else {
            ReArr.push(String(i))
          }
        }
      }
      return ReArr
    },
    SwipeEven() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 100; i++) {
        if (i % 2 !== 1) {
          if (i < 10) {
            ReArr.push(`0${i}`)
          } else {
            ReArr.push(String(i))
          }
        }
      }
      return ReArr
    },
    Undo() {
      this.items = []
      this.Historyitems = []
      this.countArry = 0
      // if (this.Historyitems.length > 1) {
      //   this.Historyitems.pop()
      //   this.items = this.Historyitems[this.Historyitems.length - 1]
      // } else {
      //   this.items = []
      //   this.Historyitems = []
      // }
    },
    CalMaxLength() {
      const CheckLengthType = this.BetType.findIndex(
        obj => obj === '19ประตู' || obj === 'รูดหน้า' || obj === 'รูดหลัง',
      )
      if (CheckLengthType >= 0) {
        this.maxlength = 1
      } else {
        this.maxlength = this.ShowDigit
      }
      return this.maxlength
    },
    CalSumAmount() {
      const SumAmount = this.items.reduce(
        (acc, ele) => acc + Number(ele.price),
        0,
      )
      if (SumAmount > this.MainWallet) {
        if (this.CheckSubmit) {
          this.SwalMes(
            'warning',
            'จำนวนเงินไม่เพียงพอ !!',
            'ยอดเงินของท่านไม่เพียงพอกรุณาฝากเงินก่อนค่ะ',
          )
          this.CheckSubmit = !this.CheckSubmit
        }
      } else {
        this.CheckSubmit = true
      }
      if (this.discount_member > 0) {
        this.SumAmount = SumAmount - ((this.discount_member * SumAmount) / 100)
        return this.SumAmount
      }
      this.SumAmount = SumAmount
      return this.SumAmount
    },
    // eslint-disable-next-line consistent-return
    Commas(x) {
      if (x) {
        if (Math.round(x) !== x && x > 0) {
          const Num = Number(x)
          const y = Num.toFixed(2)
          return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
    SwalMes(Type, Title, Html) {
      this.$swal({
        icon: Type,
        title: Title,
        html: Html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    // Modal
    // showModal() {
    //   this.$refs['my-modal'].show()
    // },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
  },
}
</script>

<style scoped>
.flex-fill {
    padding: 1px;
}

.view-rules {
    display: inline-flex;
    align-items: center;
    border: 0;
    text-align: center;
    background-color: #FCC201;
    border-radius: 13px;
    font-size: 12px;
    font-weight: 700;
    padding: 5px 15px;

}

/* td {
    border: 1px solid #707070af;
    background: #fff;
    padding: 5px;
}

tr {
    margin: auto;
    width: 320px;
} */

.form-css {
    border: none !important;
    border-radius: 10px !important;
}

.btns-bet-l {
    width: calc(100% - 110px);
}

.row-top-action {
    background-color: rgb(93, 173, 240) !important;
    /* background-image: url(/back_bg.svg) !important; */
}

.row-bottom-action {
    background-color: #0c2574 !important;
    /* background-image: url(/back_bg.svg) !important; */
}

@media only screen and (max-width: 768px) {
    .row-bottom-action {
        width: 100% !important;
        left: 0;
        bottom: 0;
    }
}

@media (max-width: 768px) {
    .bet-action-bottom .bet-detail {
        width: 50%;
        border-bottom: 1px solid #7f0000;
    }
}

.bet-action-bottom .bet-detail {
    min-width: 140px;
    padding: 10px 20px;
    border-right: 1px solid #7f0000;
}

.input-bottom-bet {
    width: calc(100% - 90px);
    height: 44px;
    padding: 10px 15px;
    font-size: 20px;
    font-weight: 700;
    color: #FF6700;
    border: none;
    border-radius: 4px;
}

@media (max-width: 768px) {

    .bet-action-bottom .bet-button,
    .bet-action-bottom .bet-form {
        margin: 0 auto;
        width: 100%;
    }
}

.bet-button {
    padding: 5px 20px 15px;
    width: 360px;
    display: flex;
}

.float-left {
    float: left !important;
}

a:focus,
input {
    outline: 0;
}

.color-sub {
    color: #fff;
}

.color-white {
    color: #FFF;
}

.font-page-title {
    font-size: 20px;
}

.bet-list-item.yellow {
    color: #edad0c;
}

.bet-list-item.orange {
    color: #FF6700;
}

.bet-list-item.green {
    color: #2CA468;
}

.bet-list-item.yellow.selected,
.bet-list-item.yellow.selected:hover {
    background-color: #FCC201;
    color: #FFF;
}

.bet-list-item {
    float: left;
    width: 100%;
    border-radius: 15px;
    background-color: #FFF;
    line-height: 20px;
    color: #818181;
    font-size: 12px;
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, .125);
    box-shadow: 0 1px 2px rgb(0 0 0 / 5%);
}

.font-page-title {
    font-size: 20px;
}

.box-bet-coin-white .box-footer,
.font-sub-title {
    font-size: 12px;
}

.bet-sm-item {
    width: 100%;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    color: #818181;
    text-align: center;
    background-color: #FFF;
    display: block;
}

.btn1.active {
    background: #3b5898 !important;
    color: #fff !important;
}

.button-num-cal {
    width: 100%;
    background: linear-gradient(180deg, #FFF 0, #F1F1F1 100%);
    border: none;
    margin: 0 0 10px;
    font-size: 30px;
}

.button-num-cals {
    width: 100%;
    background: linear-gradient(180deg, #424242 0, #202020 100%);
    border: none;
    font-size: 16px;
    color: #fff;
    border-radius: 4px;
    margin: 0 0 10px;
    font-size: 30px;
}

.button-num-calss {
    width: 100%;
    background: linear-gradient(180deg, #424242 0, #202020 100%);
    border: none;
    font-size: 16px;
    border-radius: 4px;
    color: #fff;
    margin: 0 0 10px;
    font-size: 30px;
}

.button-action-cal,
.button-num-cal {
    box-shadow: 1px 1px 2px rgb(0 0 0 / 25%);
    border-radius: 4px;
    text-align: center;
    height: 45px;
}

.clicked {
    background: #3b5898 !important;
    color: #fff !important;
}

.btns,
.input-2-box .text-control {
    font-weight: 700;
    padding: 5px 5px;
    line-height: 24px;
}

.bg-cradit {
    margin: auto;
    background-color: #fff;
    color: #000;
    width: 150px;
    font-size: 0.6rem;
    border-radius: 10px;
}

.condition-top {
    background-color: #d40000;
    border-radius: 10px 10px 0px 0px;
    padding: 5px;
    color: #fff;
}

.condition-body {
    background-color: #838383;
    border-radius: 0px 0px 10px 10px;
    color: #fff;
}

.btn-type3 {
    width: 100%;
    color: #707070;
    background-color: rgba(0, 0, 0, 0);
    padding: 10px 2px;
    /* border-radius: 25px; */
    font-weight: 700;
    border: 0px;
}

.btn-type2 {
    width: 100%;
    color: #707070;
    background-color: rgba(0, 0, 0, 0);
    padding: 10px 2px;
    font-weight: 700;
    /* border-radius: 25px; */
    border: 0px;
}

.btn-type1 {
    width: 100%;
    color: #707070;
    background-color: rgba(0, 0, 0, 0);
    padding: 10px 2px;
    font-weight: 700;
    /* border-radius: 25px; */
    border: 0px;
}

.active,
.btnp:focus {
    /* border-bottom: 4px solid #0c2574; */
    background: linear-gradient(180deg, rgb(0, 70, 23) 0, #006f09 100%);
    color: #ffffff;
}

.header-bg-content2 {
    background-color: #c3cdc5;
    /* border-bottom: 2px solid #006f09; */
    border-width: 4px;
    border-bottom-style: solid;
    border-image: linear-gradient(160deg, #b47e11, #fef1a2, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07) 1;
}

.bg-side {
    background-color: #111;
    border-radius: 5px;
    padding: 2px;
    color: #fff;
}

.pdd {
    margin: auto;
    max-width: 540px;
}

hr {
    margin-top: -2px;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right,
            rgba(0, 0, 0, 0),
            rgba(221, 221, 221, 0.75),
            rgba(0, 0, 0, 0));
}

p {
    line-height: 0.7;
}

.bg-danger {
    background-color: #dc3545 !important;
}
label {
    font-size: 1.2rem;
    font-weight: 700;
}

.text-white {
    color: #fff !important;
}

@media screen and (max-width: 768px) {
    .btn-tanghuay {
        font-size: 12px !important;
        padding-right: 4px;
    }
}

.btn-group-sm>.btn,
.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.btn-tanghuay {
    line-height: 1;
}

.action-bar.bar-white .nav-left {
    color: rgb(0, 0, 0) !important;
    font-weight: 700;
}

.action-bar.bar-white .nav-right {
    color: rgb(0, 0, 0) !important;
}
.bage-num {
    background: #053519;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
}
.form-control {
    width: 100% !important;
    font-weight: 500;
    height: 45px;
    padding: 5px;
    font-size: 1.2rem;
}
.card-sum {
    background: #f0ffbc;
    color: #fff;
    padding: 2px;
    border-radius: 5px;
    box-shadow: #aaaaaabd 0px 0px 5px;
}
.bg-aqua {
    background: #f0ffbc !important;
    color: #000 !important;
    font-weight: 600 !important;
    font-size: 1rem;
}
</style>

<style lang="scss" scoped>
@import url(https://unpkg.com/@8bu/vue-otp-input@1.1.6/dist/vue-otp-input.css);
@import '@core/scss/vue/pages/page-auth.scss';

.form-control {
  height: 35px !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0rem;
}

.style-1 {
    .otp-input-8-field input {
        border-radius: 5;
        border: 2px solid #c50000;

        &:not(:last-child) {
            margin-right: 0.5rem;
        }
    }
}

.col-4,
.col-sm-6 {
    padding: 2rem;
    max-width: 100%;
    flex-grow: 1;
}
</style>
